<template>
  <div class="site-wrap">
    <Header :mode="2" :cityCode="cityCode" :searchKey="searchKey"></Header>
    <div class="main-wrap">
      <MapListTemplate>
        <div class="left-panel no-scrollbar" slot="leftPanel">
          <div class="left-navs-tabs">
            <ul>
              <li
                v-for="(item, index) in navs"
                :key="'nav_' + index"
                :class="{ cur: item.key === curNav }"
                @click="curNav = item.key"
              >
                {{ item.label }}
              </li>
              <li class="nav-collect" v-if="locationCode">
                <Tooltip
                  transfer
                  content="收藏"
                  placement="top"
                  v-if="!isCollect"
                >
                  <i
                    class="collect icon-collect"
                    @click.stop="addCollect(locationCode)"
                  ></i>
                </Tooltip>
                <Tooltip transfer content="已收藏" placement="top" v-else>
                  <i
                    class="collect icon-collected"
                    @click.stop="delCollect(locationCode)"
                  ></i>
                </Tooltip>
                <Tooltip transfer content="分享" placement="top" v-if="false">
                  <i class="share icon-share"></i>
                </Tooltip>
              </li>
            </ul>
          </div>
          <div class="left-tabs-cont">
            <template v-if="curNav === 'customersFeature'">
              <section v-if="!userInfo || !userInfo.userMember||userInfo.userMember.key === 'normal' ||userInfo.userMember.key === 'guest' " class="g-vip-section g-vip-section-small">
                <div role="button" class="g-vip-btn" @click="toVip">去升级</div>
                <div>
                  <img
                    :src="require('@/assets/images/demo_pointer.png')"
                    width="396"
                  />
                </div>
                <div class="diliver"></div>
              </section>
            </template>
            <span class="data-update-panel">数据更新日期: {{ yesterday }}</span>
            <!-- 配套信息 -->
            <template v-if="curNav === 'supportingFacilities'">
              <SupportingFacilities
                :address="markerAddress"
                :polygon="resultGeom"
              ></SupportingFacilities>
            </template>
            <!-- 客群特征 -->
            <template v-if="curNav === 'customersFeature'">
              <CustomersFeature :polygon="resultGeom"></CustomersFeature>
            </template>
            <!-- 业态分布 -->
            <template v-if="curNav === 'formatAnalysis'">
              <FormatAnalysis :polygon="resultGeom"></FormatAnalysis>
            </template>
          </div>
        </div>
        <MapPanel
          ref="mapPanel"
          :isSelectCity="true"
          :mapTools="mapTools"
          :legendsObj="legendsObj"
          :ignoreFitChina="true"
          :isWatchLocation="true"
          @loaded="handleMapLoaded"
          @click="handleMapClick"
          @selectCity="handleSelectCity"
          @startDraw="handleStartDraw"
          @drawPoint="handleDrawPoint"
          @drawPolygon="handleDrawPolygon"
          @searchPoi="searchPoi"
        >
        </MapPanel>
      </MapListTemplate>
    </div>
    <ModalUpgrade :isShow="isShow" @close="isShow = false"></ModalUpgrade>
  </div>
</template>

<script>
import MapPanel from "@/components/MapPanel.vue";
import * as turf from "@turf/turf";
import Vue from "vue";
import InfoWindowLocation from "@/components/InfoWindowLocation.vue";
import SupportingFacilities from "@/components/location/SupportingFacilities.vue";
import CustomersFeature from "@/components/location/CustomersFeature.vue";
import FormatAnalysis from "@/components/location/FormatAnalysis.vue";
import ModalUpgrade from "@/components/ModalUpgrade.vue"
import collectMixin from "@/mixins/collect.js";
import { mapGetters } from "vuex";
import {
  GetCurLocation,
  ReachableDomain,
  GetCommonInfo,
  GetCodeInfo,
  GetDetailBrowsingRecord,
  SearchMatching,
} from "@/service";
import {
  cloneObj,
  formatFieldsList,
  formatFieldsList2,
  timeFormat,
} from "js/utils.js";
import { geom2wkt, wkt2geom } from "js/map/map.utils.js";
export default {
  metaInfo: {
    title: "查位置",
    meta: [
      {
        name: "keywords",
        content:
          "千里目,位置数据,POI数据,地理画像,区域配套,周边配套,人口规模,居住人口,工作人口,住宅小区,写字楼,商场,商超,便利店,学校,医院,公司企业,交通,租金,房价,客群特征,用户画像,商业业态,可达域查询",
      },
      {
        name: "description",
        content:
          "千里目覆盖全国1.5亿+POI地理位置画像，支持精准搜索、自定义查询、可达域查询等多种方式快速查看任意区域相关数据，包含区域配套、人口规模、住宅小区、写字楼、商场、商超、便利店、学校、医院、公司企业、交通、客群特征、商业业态等详细数据。",
      },
    ],
  },
  mixins: [collectMixin],
  data() {
    return {
      selectCityName: "",
      isMapLoaded: false,
      locationCode: "", // 位置code
      isCollect: false, // 是否收藏
      isLocation: true,
            // "searchAddress",
      mapTools: [

        "drawPoint",
        "drawCircle",
        "drawRect",
        "drawPolygon",
        "drawClear",
      ],
      legends: [
        {
          title: "住宅区",
          color: "#5F6AEB",
          key: "community",
        },
        {
          title: "商业区",
          color: "#986FFF",
          key: "business",
        },
        {
          title: "写字楼",
          color: "#FD767D",
          key: "office",
        },
        {
          title: "学校",
          color: "#FF9800",
          key: "school",
        },
        {
          title: "医院",
          color: "#FCC44D",
          key: "hospital",
        },
        {
          title: "地铁站",
          color: "#3CD19C",
          key: "subway",
        },
        {
          title: "公交站",
          color: "#28C7F3",
          key: "bus",
        },
      ],
      // 数据更新日期
      yesterday: timeFormat(new Date(), "Y/m/d"),
      baseNormalCenter: [116.309834, 40.042785],
      normalCode: "57f6f55a-ce07-469d-a13f-de9c24dad4cc",
      normalMarkerRadius: 1500,
      markerObj: {
        center: [],
        type: "",
        radius: 1500,
      },
      markerAddress: "",

      navs: [
        { label: "配套信息", key: "supportingFacilities" },
        { label: "客群特征", key: "customersFeature" },
        { label: "业态分布", key: "formatAnalysis" },
      ],
      curNav: "supportingFacilities",

      markerInfoWindow: null,
      polygonInfoWindow: null,
      poiInfoWindow: null,

      searchGeom: "",
      isSearchResult: false,
      cityCode: "",
      searchKey: "",
      isShow:false
    };
  },

  computed: {
    ...mapGetters({
      userInfo: "getUserInfo",
      token: "getToken",
    }),
    legendsObj() {
      return {
        title: "全部配套",
        list: this.legends,
      };
    },
    resultGeom() {
      if (this.isSearchResult) {
        return geom2wkt(this.searchGeom);
      }
      return "";
    },
    sort() {
      return this.$store.getters.getSortId("location");
    },
  },
  methods: {
    async initData() {
      this.initLoadingStatus = false;

      await new Promise((resolve) => {
        let inter = setInterval(() => {
          if (this.isMapLoaded) {
            clearInterval(inter);
            resolve();
          }
        }, 10);
      });
      let center = this.baseNormalCenter;
      let id = this.$route.query.id;
      let title = "";
      let address = "";
      if (!id) {
        let params = {
          word: "自主创新大厦",
          matchingSort: this.sort,
        };
        let res = await SearchMatching(params);
        if (res && res.code === 200) {
          let list = res.data.items || [];
          if (list.length > 0) {
            let item = list[0];
            id = item.code;
          }
        }
      }
      if (id) {
        this.locationCode = id;
        this.checkLocationCollect();
        let params = {
          code: id,
          sort: this.sort,
        };
        let res = await GetCodeInfo(params);
        if (res && res.code === 200) {
          let data = res.data;
          if (data.items && data.items.geom) {
            let item = data.items;
            let geom = item.geom;
            if (typeof geom === "string") {
              geom = JSON.parse(geom);
            }
            center = geom.coordinates;
            title = item.name;
            address = item.location;
          }
        }
      }
      let feature = {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: center,
        },
        properties: {
          title: title,
          address: address,
          code: id,
        },
      };
      const featureBak = JSON.parse(JSON.stringify(feature));
      delete featureBak.properties;
      const res = await GetDetailBrowsingRecord({
        module: "locationDetail",
        detailID: this.locationCode || JSON.stringify(featureBak),
      });
      if (res && res.code == 200 && res.data == "success") {
        await this.handleDrawPoint(feature);
        this.queryLegendsPois();
      }
    },
    async checkLocationCollect() {
      let list = [
        {
          code: this.locationCode,
          isCollect: false,
        },
      ];
      list = await this.checkListCollectStatus(list);
      this.isCollect = list[0].isCollect;
    },
    toVip() {
      // window.open("/vip", "_blank");
      // 打开留资弹窗
      this.isShow = true
    },
    handleMapLoaded() {
      this.isMapLoaded = true;
    },
    // 点击
    handleMapClick(feature) {
      if (!feature) {
        return;
      }
      let properties = feature.properties;
      if (properties.drawPoint) {
        if (this.markerInfoWindow) {
          this.markerInfoWindow.reopen();
        } else {
          this.handleDrawPoint(feature);
        }
      } else if (
        properties.drawCircle ||
        properties.drawRectangle ||
        properties.drawPolygon
      ) {
        if (this.polygonInfoWindow) {
          this.polygonInfoWindow.reopen();
        } else {
          this.openPolygonInfoWindow(feature);
        }
      } else if (properties.isPoi) {
        this.openPoiInfoWindow(feature);
      }
    },
    async handleSelectCity(city) {
      let cityCode = city.adcode;
      this.cityCode = city.adcode;
      localStorage.setItem("locationCityCode", this.cityCode);
      let district = await this.mapPanel.searchDistrict(cityCode, true);
      this.mapPanel.fitBounds(district.bbox);
    },
    searchPoi(searchPoi) {
      let center = searchPoi.center;
      let feature = {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: center,
        },
        properties: searchPoi.properties || {},
      };
      this.locationCode = "";
      this.handleDrawPoint(feature);
    },
    // 绘制之前清空
    handleStartDraw() {
      let layerIds = ["marker_point", "marker_circle", "polygons"];
      for (let i = 0, n = layerIds.length; i < n; i++) {
        let layerId = layerIds[i];
        this.mapPanel.changeLayerShow(layerId, false);
      }
      this.mapPanel.closeWindowInfo();
      this.mapPanel.clearLegends();
      this.markerAddress = "";
      this.locationCode = "";
      if (this.markerInfoWindow) {
        this.markerInfoWindow.close();
      }
      if (this.polygonInfoWindow) {
        this.polygonInfoWindow.close();
      }
    },
    // 绘制点位
    async handleDrawPoint(feature) {
      if (!feature.properties) {
        feature.properties = {};
      }
      const DETAILID = {
        type: "Feature",
        geometry: feature.geometry,
      };
      const resp = await GetDetailBrowsingRecord({
        module: "locationDetail",
        detailID: this.locationCode || JSON.stringify(DETAILID),
      });
      if (resp && resp.code == 200 && resp.data == "success") {
        feature.properties.type = "drawMarker";
        feature.properties.drawPoint = true;
        let features = [feature];
        let options = {
          icon: "/images/marker_blue2.png",
          iconSize: [22, 33],
          anchor: "bottom-center",
        };
        this.mapPanel.setMarkers("marker_point", features, options);

        let center = feature.geometry.coordinates;
        this.markerObj.type = "surround";
        this.markerObj.center = center;
        this.markerObj.radius = this.normalMarkerRadius;
        this.drawMarkerPolygon();

        let infoObj = {
          center,
          title: "",
          address: "",
        };
        if (feature.properties.title) {
          infoObj.title = feature.properties.title;
          infoObj.address = feature.properties.address;
        }
        let res = await GetCurLocation({
          lon: center[0],
          lat: center[1],
        });
        if (res && res.code === 200) {
          let data = res.data.items;
          if (!feature.properties.code) {
            infoObj.title = data.building || data.cityName;
            infoObj.address = data.formattedAddress;
          }
          let cityName = data.cityName;
          this.$refs.mapPanel.changeCityName(cityName);

          this.markerAddress = infoObj.address;
        }
        this.openPointInfowindow(infoObj);
      }
    },
    // 点位弹窗
    openPointInfowindow(obj) {
      let center = obj.center;
      let InfoWindow = Vue.extend(InfoWindowLocation);
      let instance = new InfoWindow({
        propsData: {
          title: obj.title,
          address: obj.address,
          isPoiSearch: true,
          normalSet: {
            type: "surround",
            radius: this.normalMarkerRadius,
          },
        },
      });
      instance.$on("changeSearchType", (type) => {
        this.markerObj.type = type;
        if (type === "surround") {
          this.markerObj.radius = this.normalMarkerRadius;
          const mapCenter = this.$refs.mapPanel.getMapCenter()
          this.$refs.mapPanel.setMapCenter(mapCenter)
        }
        this.drawMarkerPolygon();
      });
      instance.$on("changeRadius", (radius) => {
        this.markerObj.radius = radius;
        this.drawMarkerPolygon();
      });
      instance.$on("doSubmit", async (obj) => {
        if (obj.type === "domain") {
          this.markerObj.travelType = obj.travelType;
          this.markerObj.travelTime = obj.travelTime;
          await this.getMarkerDomainSearch();
        }
        // 查询其中所有的配套点
        this.queryLegendsPois();
      });
      instance.$mount();
      let content = instance.$el;
      let size = instance.size;
      this.markerInfoWindow = this.mapPanel.openInfoWindow(center, content, {
        offset: [0, -23],
        autoMove: true,
        anchor: "bottom-center",
        size: size,
        tag: "pointInfowindow",
      });
      const mapCenter = this.$refs.mapPanel.getMapCenter()
      this.$refs.mapPanel.setMapCenter([parseFloat(mapCenter[0]), parseFloat(mapCenter[1]) + 0.006764999999994359])
    },
    // 可达域查询
    async getMarkerDomainSearch() {
      let center = this.markerObj.center;
      let travelType = this.markerObj.travelType;
      let travelTime = this.markerObj.travelTime;
      let params = {
        lon: center[0] + "",
        lat: center[1] + "",
        tripType: travelType,
        minute: Number(travelTime),
      };
      let res = await ReachableDomain(params);
      if (res && res.code === 200) {
        let geom = res.data.items.geom;
        this.drawMarkerPolygon(geom);
      }
    },
    // 绘制点位相关的圆
    drawMarkerPolygon(polygon) {
      let type = this.markerObj.type;
      let center = this.markerObj.center;
      let radius = this.markerObj.radius;
      let features = [];
      if (type === "surround") {
        let circle = turf.circle(center, radius / 1000, {
          steps: 256,
          units: "kilometers",
        });
        this.searchGeom = JSON.stringify(circle.geometry);
        circle.properties.drawPoint = true;
        features = [circle];
      } else {
        if (polygon) {
          if (typeof polygon === "string") {
            polygon = JSON.parse(polygon);
          }
          let feature = {
            type: "Feature",
            geometry: polygon,
            properties: {
              drawPoint: true,
            },
          };
          this.searchGeom = JSON.stringify(feature.geometry);
          features = [feature];
        }
      }
      let options = {};
      this.mapPanel.setPolygons("marker_circle", features, options);
      if (features.length > 0) {
        this.mapPanel.fitBounds(features, true, [100, 100, 100, 100]);
      }
      this.mapPanel.clearLegends();
    },
    // 绘制面
    async handleDrawPolygon(feature) {
      let features = [feature];
      const res = await GetDetailBrowsingRecord({
        module: "locationDetail",
        detailID: JSON.stringify(feature.geometry),
      });
      if (res && res.code == 200 && res.data == "success") {
        let options = {};
        this.mapPanel.setPolygons("polygons", features, options);
        this.mapPanel.fitBounds(features, true, [100, 100, 100, 100]);
        this.searchGeom = JSON.stringify(feature.geometry);
        this.openPolygonInfoWindow(feature);
      } else {
        this.mapPanel.clearMap();
      }
    },
    // 画面弹窗
    openPolygonInfoWindow(feature) {
      let polygon = feature.geometry;
      let properties = feature.properties;
      let center = turf.center(polygon);
      center = center.geometry.coordinates;
      let baseArea = turf.area(polygon);
      let area = baseArea;
      let areaStr = "";
      let areaExt = "平方米";
      if (area > 1000 * 1000) {
        area = area / (1000 * 1000);
        areaExt = "平方公里";
      }
      area = parseFloat(area.toFixed(2));
      areaStr = `面积: ${area}${areaExt}`;

      let title = "";
      if (properties.drawCircle) {
        title = "画圆查询";
      } else if (properties.drawRectangle) {
        title = "画矩形查询";
      } else {
        title = "自定义查询";
      }
      let list = [areaStr];
      let InfoWindow = Vue.extend(InfoWindowLocation);
      let instance = new InfoWindow({
        propsData: {
          title: title,
          list: list,
        },
      });
      instance.$on("doSubmit", async (obj) => {
        if (baseArea > 9 * 1000 * 1000) {
          this.$Message.info("最大查询面积不能超过 9 平方公里");
          return;
        }
        this.queryLegendsPois();
      });
      instance.$mount();
      let content = instance.$el;
      let size = instance.size;
      this.polygonInfoWindow = this.mapPanel.openInfoWindow(center, content, {
        offset: [0, 0],
        autoMove: true,
        anchor: "bottom-center",
        size: size,
        tag: "polygonInfoWindow",
      });
    },
    // 查询poi
    async queryLegendsPois() {
      let geom = this.searchGeom;
      for (let i = 0, n = this.legends.length; i < n; i++) {
        this.queryAreaPois(i, geom);
      }
      this.isSearchResult = true;
    },
    // 请求选中面积中的配套信息
    async queryAreaPois(legendIndex, geom) {
      let key = this.legends[legendIndex].key;
      let cate = this.legends[legendIndex].title;
      let identify = this.$store.state.tabsIdentifyLocation[key];
      let fields = cloneObj(this.$store.state.tabsLocationFields[key]);
      fields.push("位置");
      let polygon = geom2wkt(geom);
      let params = {
        identify: identify,
        fields: fields,
        filters: [
          {
            field: "geom",
            cond: 0,
            value: [polygon],
          },
        ],
        pages: {
          page: 1,
          size: 10000,
        },
      };
      let extObj = {
        均价: "元/㎡",
        平均租金: "元/㎡/天",
        占地面积: "㎡",
      };
      let res = await GetCommonInfo(params);
      // console.log(key,identify,res)
      if (res && res.code === 200) {
        let list = formatFieldsList2(res.data);
        let features = [];
        for (let i = 0, n = list.length; i < n; i++) {
          let item = list[i];
          let attrs = [];
          attrs.push({
            label: "分类",
            value: cate,
          });
          for (let key in item) {
            if (key === "位置") {
              continue;
            }
            let val = item[key];
            if (val) {
              if (extObj[key]) {
                val += extObj[key];
              }
            }
            attrs.push({
              label: key,
              value: val || "--",
            });
          }
          item.attrs = attrs;
          item.isPoi = true;
          let geom = item["位置"];
          if (!geom) {
            continue;
          }
          geom = wkt2geom(geom);
          let feature = {
            type: "Feature",
            properties: item,
            geometry: geom,
          };
          features.push(feature);
        }
        this.mapPanel.setLegendSource(legendIndex, features);
      }
    },
    openPoiInfoWindow(feature) {
      let properties = feature.properties;
      let center = feature.geometry.coordinates;

      let title = "";
      let list = [];
      let attrs = properties.attrs || [];
      for (let i = 0, n = attrs.length; i < n; i++) {
        let item = attrs[i];
        if (item.label.includes("名称")) {
          title = item.value;
          continue;
        }
        list.push(`${item.label}: ${item.value}`);
      }
      let InfoWindow = Vue.extend(InfoWindowLocation);
      let instance = new InfoWindow({
        propsData: {
          title,
          list,
          btnText: "确定",
          hideBtn: true,
        },
      });
      instance.$on("doSubmit", (obj) => {});
      instance.$mount();
      let content = instance.$el;
      this.poiInfoWindow = this.mapPanel.openInfoWindow(center, content, {
        offset: [0, -10],
        autoMove: true,
        anchor: "bottom-center",
      });
    },
  },
  mounted() {
    let searchKey = this.$route.query.key;
    if (searchKey) {
      this.searchKey = searchKey;
    }
    this.mapPanel = this.$refs.mapPanel;
    this.initData();
    this.cityCode = localStorage.getItem("locationCityCode") || localStorage.getItem("cityCode");
  },
  components: {
    MapPanel,
    SupportingFacilities,
    CustomersFeature,
    FormatAnalysis,
    ModalUpgrade
  },
};
</script>

<style></style>
