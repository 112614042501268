var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "customers-box" },
    [
      _c("CustomCardBg", { attrs: { list: _vm.cardList } }),
      _c(
        "CustomCard",
        { attrs: { title: "性别分布" } },
        [
          _c("CustomProgress", {
            attrs: { width: "60px", list: _vm.genderList },
          }),
        ],
        1
      ),
      _c(
        "CustomCard",
        { attrs: { title: "年龄分布", height: "230px" } },
        [
          _c("LineChart", {
            attrs: {
              echartData: _vm.ageList,
              lineColor: "#3519FB",
              textColor: "#848295",
              colorStopsStart: "rgba(62,78,255,0.1)",
              colorStopsEnd: "rgba(62,78,255,0)",
              maxLength: 7,
              isSmooth: false,
              tooltipFormatter: "{c}%",
            },
          }),
        ],
        1
      ),
      _c(
        "CustomCard",
        { attrs: { title: "学历分布", height: "230px" } },
        [_c("pieChart", { attrs: { echartData: _vm.educationList } })],
        1
      ),
      _c(
        "CustomCard",
        { attrs: { title: "家庭户数代数分布" } },
        [
          _c("CustomProgress", {
            attrs: { width: "80px", list: _vm.familyList },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }