<template>
  <div class="progress-box" v-loading="loading">
    <div
      class="progress-item"
      v-for="(item, index) in list"
      :key="'progress_' + index"
    >
      <div class="progress-item-title" :style="{ width: width }">
        {{ item.title }}
      </div>
      <Progress
        :percent="getPrecent(item.percent)"
        :stroke-color="item.strokeColor || '#3519FB'"
        class="progress-item-content"
        :class="item.class || []"
        :style="{ width: `calc(100% - ${width})` }"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomProgress",
  props: {
    width: {
      type: String,
      default: "60px",
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    list: {
      handler: function () {
        this.loading = false;
      },
      deep: true,
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  methods: {
    getPrecent(percent) {
      if (typeof percent === "string") {
        return parseFloat(percent);
      }
      return percent || 0;
    },
  },
};
</script>

<style lang="less" scoped>
.progress-box {
  position: relative;
  .progress-item {
    display: flex;
    margin-bottom: 16px;
    .progress-item-title {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
      line-height: 20px;
      margin-right: 8px;
    }
    .progress-item-content {
      /deep/.ivu-progress-inner {
        box-sizing: border-box;
        border-radius: 2px;
        background-color: rgba(0, 0, 0, 0.06);
        .ivu-progress-bg {
          border-radius: 2px 102px 102px 2px;
        }
      }
      /deep/ .ivu-progress-text-inner {
        font-size: 14px;
        font-weight: 500;
        color: #3519fb;
        line-height: 20px;
      }
      /deep/ .ivu-progress-outer {
        padding-right: 40px;
        margin-right: -40px;
      }
    }
    .progress-type1 {
      /deep/ .ivu-progress-inner {
        background-color: rgba(0, 187, 135, 0.06);
        border: 1px solid #85eddc;
      }
      /deep/ .ivu-progress-text-inner {
        color: #00bb87;
      }
    }
    .progress-type2 {
      /deep/ .ivu-progress-inner {
        background-color: rgba(249, 144, 0, 0.08);
        border: 1px solid #fed59d;
      }
      /deep/ .ivu-progress-text-inner {
        color: #f99000;
      }
    }
  }
}
</style>
