<template>
  <div class="cardBg-box" v-loading="loading">
    <div
      class="cardBg-item"
      v-for="(item, index) in list"
      :key="'cardbg_' + index"
      :style="{
        backgroundImage: `url(${item.image})`,
      }"
    >
      <div class="cardBg-item-name">{{ item.name }}</div>
      <div class="cardBg-item-value" :style="{ color: item.color }">
        {{ numFormat(item.value || 0) }}
      </div>
    </div>
  </div>
</template>

<script>
import { numFormat } from "@/assets/javascript/utils.js";
export default {
  name: "CustomcardBg",
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    list: {
      handler: function (val, oldVal) {
        this.loading = false;
      },
      deep: true,
    },
  },
  methods: {
    numFormat,
  },
  data() {
    return {
      loading: true,
    };
  },
};
</script>

<style lang="less" scoped>
.cardBg-box {
  margin: 10px 0px 17px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  position: relative;
  .cardBg-item {
    width: 182px;
    height: 68px;
    padding: 11px 0px 13px 70px;
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    .cardBg-item-name {
      font-size: 12px;
      font-weight: 500;
      color: #848295;
      line-height: 22px;
    }
    .cardBg-item-value {
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
    }
  }
}
</style>
