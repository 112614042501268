<template>
  <div class="format-box">
    <CustomData :dataList="commercialList"></CustomData>
    <CustomCard title="业态详细分布情况" height="300px">
      <template>
        <CustomTabs
          :tabList="tabList"
          :active="activeTab"
          @activeTab="tabClick"
        ></CustomTabs>
        <pieChart
          :echartData="pieChartData"
          :legendShow="true"
          :center="center"
        ></pieChart>
      </template>
    </CustomCard>
  </div>
</template>

<script>
import CustomData from "../CustomData.vue";
import CustomCard from "@/components/CustomCard.vue";
import CustomTabs from "@/components/CustomTabs.vue";
import pieChart from "@/components/chart/pieChart.vue";
import { GetCommonInfo } from "@/service";
import { formatFieldsList2 } from "js/utils.js";
export default {
  props: ["polygon", "address"],
  data() {
    return {
      commercialList: [
        { title: "美食餐饮", value: 0, unit: "个" },
        { title: "生活服务", value: 0, unit: "个" },
        { title: "宾馆酒店", value: 0, unit: "个" },
        { title: "医疗卫生", value: 0, unit: "个" },
        { title: "体育休闲", value: 0, unit: "个" },
        { title: "金融银行", value: 0, unit: "个" },
      ],
      // 选中的tab
      activeTab: "金融银行",
      // 业态详细分布情况 tab数据
      tabList: [
        { label: "金融银行", name: "金融银行" },
        { label: "美食餐饮", name: "美食餐饮" },
        { label: "生活服务", name: "生活服务" },
        { label: "宾馆酒店", name: "宾馆酒店" },
        { label: "医疗卫生", name: "医疗卫生" },
        { label: "体育休闲", name: "体育休闲" },
      ],
      // 业态详细分布情况 饼图数据
      pieChartData: [
        { name: "零售", value: -1 },
        { name: "生活服务", value: -1 },
        { name: "餐饮", value: -1 },
        { name: "休闲娱乐", value: -1 },
        { name: "儿童亲子", value: -1 },
        { name: "汽车销售", value: -1 },
        { name: "跨界结合店铺", value: -1 },
        { name: "酒店", value: -1 },
      ],
      center: ["50%", "30%"],
    };
  },
  components: {
    CustomCard,
    CustomTabs,
    CustomData,
    pieChart,
  },
  watch: {
    polygon() {
      this.init();
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getCommercialList();
      this.getPieChartData();
    },
    // 获取业态分布
    async getCommercialList() {
      let params = {
        identify: "sg-data-位置-业态分布",
        filters: [
          {
            field: "geom",
            cond: 0,
            value: [this.polygon],
          },
        ],
        fields: [
          "美食餐饮",
          "生活服务",
          "宾馆酒店",
          "医疗卫生",
          "体育休闲",
          "金融银行",
        ],
      };
      let res = await GetCommonInfo(params);
      // console.log("业态分布", res);
      if (res && res.code === 200) {
        let details = res.data.items ? res.data.items[0] : [];
        this.commercialList[0].value = details[0] || 0;
        this.commercialList[1].value = details[1] || 0;
        this.commercialList[2].value = details[2] || 0;
        this.commercialList[3].value = details[3] || 0;
        this.commercialList[4].value = details[4] || 0;
        this.commercialList[5].value = details[5] || 0;
      }
    },
    // 获取业态详细分布情况
    async getPieChartData() {
      let params = {
        identify: "sg-data-位置-业态详细分布情况",
        filters: [
          {
            field: "geom",
            cond: 0,
            value: [this.polygon],
          },
        ],
        fields: [`${this.activeTab}中类名称`, `${this.activeTab}中类数量`],
      };
      let res = await GetCommonInfo(params);
      // console.log("业态详细分布情况", res, formatFieldsList2(res.data || {}));
      if (res && res.code === 200) {
        let list = formatFieldsList2(res.data || {});
        this.pieChartData = list.map((item) => {
          return {
            name: item[res.data.fields[0]],
            value: item[res.data.fields[1]],
          };
        });
      }
    },
    // tab点击事件
    tabClick(name) {
      this.activeTab = name;
      this.getPieChartData();
    },
  },
};
</script>

<style lang="less" scoped>
.format-box {
}
</style>
