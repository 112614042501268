<template>
  <div class="info-window-wrap info-location-wrap">
    <div class="info-title">{{ title }}</div>
    <div class="info-cont">
      <p v-if="address">
        <i class="icon-marker-grey info-location-icon"></i>
        {{ address }}
      </p>
      <p v-for="(str, index) in list" :key="'list_' + index">
        {{ str }}
      </p>
    </div>
    <div class="info-location-selects" v-if="isPoiSearch">
      <ul class="select-navs">
        <li
          v-for="(item, index) in navs"
          :key="'nav_' + index"
          :class="{ cur: curNav === item.key }"
          @click="selectNav(item.key)"
        >
          {{ item.label }}
        </li>
      </ul>
      <div class="surround-box" v-if="curNav === 'surround'">
        <div class="info-title2">查询范围</div>
        <Slider
          class="info-slider"
          v-model="surroundRadius"
          :min="surroundRounds[0]"
          :max="surroundRounds[1]"
          :step="surroundStep"
          show-tip="always"
          :tip-format="handleTip"
          @on-change="handleChangeRadius"
        ></Slider>
      </div>
      <div class="domain-box" v-if="curNav === 'domain'">
        <Form :label-width="60">
          <FormItem label="出行方式">
            <Select class="domain-inpt" v-model="travelType">
              <Option
                v-for="item in driveTypeList"
                :value="item.value"
                :key="item.value"
                >{{ item.label }}</Option
              >
            </Select>
          </FormItem>
          <FormItem label="出行时间">
            <InputNumber
              class="domain-inpt"
              maxlength="2"
              v-model="travelTime"
              type="number"
              placeholder="添加出行时间"
              @on-blur="handleChangeTravelTime"
            ></InputNumber>
            <span class="domain-unit">分钟</span>
          </FormItem>
        </Form>
      </div>
    </div>
    <div class="info-btns" v-if="!hideBtn">
      <div class="info-btn info-btn-focus" @click="doSearch">
        {{ btnText || "查询" }}
      </div>
    </div>
  </div>
</template>

<script>
import "style/infowindow.less";
export default {
  props: [
    "title",
    "address",
    "list",
    "btnText",
    "normalSet",
    "isPoiSearch",
    "hideBtn",
  ],
  data() {
    return {
      navs: [
        { label: "周边查询", key: "surround" },
        { label: "可达域查询", key: "domain" },
      ],
      curNav: "",
      surroundRadius: 500, // 查询范围
      surroundRounds: [500, 3000],
      surroundStep: 500,
      // 出行方式
      travelType: "walking",
      driveTypeList: [
        { value: "walking", label: "步行" },
        { value: "cycling", label: "骑行" },
        { value: "driving", label: "驾车" },
      ],
      travelTime: null,
    };
  },
  computed: {
    size() {
      let size = [260, 276];
      if (!this.isPoiSearch) {
        size = [260, 120];
      }
      return size;
    },
  },
  methods: {
    initData(ignoreType) {
      let normalSet = this.normalSet;
      if (normalSet) {
        if (!ignoreType) {
          this.curNav = normalSet.type || "surround";
        }
        this.surroundRadius = normalSet.radius || 500;
        this.travelType = normalSet.travelType || "walking";
      }
    },
    selectNav(type) {
      this.curNav = type;
      this.initData(true);
      this.$emit("changeSearchType", type);
    },
    handleChangeRadius(value) {
      this.$emit("changeRadius", value);
    },
    doSearch() {
      let type = this.curNav;
      if (type === "domain") {
        if (!this.travelTime) {
          this.$Message.info("请输入出行时间");
          return;
        }
      }
      let obj = {
        type,
        radius: this.surroundRadius,
        travelType: this.travelType,
        travelTime: this.travelTime,
      };
      this.$emit("doSubmit", obj);
    },
    handleTip(num) {
      return `${num}米`;
    },
    handleChangeTravelTime() {
      let val = this.travelTime;
      if (val < 5) {
        this.travelTime = 5;
      } else if (val > 30) {
        this.travelTime = 30;
      }
    },
  },
  mounted() {
    this.initData();
  },
};
</script>

<style lang="less">
@import "style/config.less";
.info-location-wrap {
  width: 260px;
  padding: 14px 0;
  .info-title,
  .info-cont {
    padding: 0 16px;
  }
  .info-title {
    font-size: 16px;
    line-height: 22px;
  }
  .info-cont {
    line-height: 18px;
    color: hsla(246, 8%, 55%, 1);
    .info-location-icon {
      width: 12px;
      height: 14px;
      vertical-align: middle;
    }
  }
  .info-location-selects {
    ul.select-navs {
      width: 100%;
      display: flex;
      li {
        width: 50%;
        font-size: 14px;
        line-height: 36px;
        font-weight: 500;
        text-align: center;
        color: hsla(246, 8%, 55%, 0.7);
        background: hsla(240, 60%, 99%, 1);
        border: 1px solid hsla(0, 0%, 0%, 0.02);
        cursor: pointer;
        &:hover {
          background: hsla(247, 97%, 54%, 0.03);
        }
        &.cur {
          color: @focusColor;
          background: hsla(247, 97%, 54%, 0.06);
        }
      }
    }
    .surround-box {
      padding: 20px 18px;
      .info-title2 {
        font-size: 12px;
        font-weight: 500;
        color: #000;
      }
      .info-slider {
        margin-top: 42px;
        padding: 0 10px;
      }
    }
    .domain-box {
      padding: 16px 14px;
      color: hsla(0, 0%, 0%, 0.8);
      .ivu-form {
        .ivu-form-item {
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        .ivu-form-item-label {
          padding-right: 0;
        }
        .ivu-form-item-content {
          padding-left: 8px;
        }
      }
      .domain-unit {
        font-size: 12px;
        margin-left: 4px;
        display: inline-block;
      }
      .domain-inpt {
        width: 136px;
        display: inline-block;
      }
    }
  }
  .info-btns {
    width: 100%;
    padding: 0 16px;
    .info-btn {
      cursor: pointer;
    }
  }
}
.info-slider {
  .ivu-slider-button {
    border-color: @focusColor;
  }
  .ivu-tooltip-arrow {
    border-top-color: @focusColor;
  }
  .ivu-tooltip-inner {
    font-size: 12px;
    padding: 1px 6px;
    min-height: 18px;
    border: 1px solid @focusColor;
    color: @focusColor;
    background-color: #fff;
  }
}
</style>
