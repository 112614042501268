<template>
  <div class="customers-box">
    <CustomCardBg :list="cardList"></CustomCardBg>
    <CustomCard title="性别分布">
      <CustomProgress width="60px" :list="genderList"></CustomProgress>
    </CustomCard>
    <CustomCard title="年龄分布" height="230px">
      <LineChart
        :echartData="ageList"
        :lineColor="'#3519FB'"
        :textColor="'#848295'"
        :colorStopsStart="'rgba(62,78,255,0.1)'"
        :colorStopsEnd="'rgba(62,78,255,0)'"
        :maxLength="7"
        :isSmooth="false"
        tooltipFormatter="{c}%"
      ></LineChart>
    </CustomCard>
    <CustomCard title="学历分布" height="230px">
      <pieChart :echartData="educationList"></pieChart>
    </CustomCard>
    <CustomCard title="家庭户数代数分布">
      <CustomProgress width="80px" :list="familyList"></CustomProgress>
    </CustomCard>
  </div>
</template>

<script>
import CustomCard from "@/components/CustomCard.vue";
import CustomCardBg from "@/components/CustomCardBg.vue";
import CustomProgress from "@/components/CustomProgress.vue";
import LineChart from "@/components/chart/lineChart.vue";
import pieChart from "@/components/chart/pieChart.vue";
import { GetCommonInfo } from "@/service";
import { formatFieldsList2, numFormat } from "js/utils.js";
export default {
  props: ["polygon"],
  data() {
    return {
      // 卡片列表数据
      cardList: [
        {
          name: "总人口数",
          value: -1,
          color: "#3519fb",
          image: require("@/assets/images/bg-cardBg1.png"),
        },
        {
          name: "总家庭户数",
          value: -1,
          color: "#00BB87",
          image: require("@/assets/images/bg-cardBg2.png"),
        },
      ],
      // 性别分布进度条数据
      genderList: [
        {
          title: "男性分布",
          percent: undefined,
          strokeColor: ["#00BB87", "#00DFBC"],
          class: ["progress-type1"],
        },
        {
          title: "女性分布",
          percent: undefined,
          strokeColor: ["#F99000", "#FFB754"],
          class: ["progress-type2"],
        },
      ],
      // 年龄分布折线数据
      ageList: [],
      // 教育机构饼图数据
      educationList: [],
      // 家庭户数代数分布进度条数据
      familyList: [],
    };
  },
  computed: {},
  components: {
    CustomCard,
    CustomCardBg,
    CustomProgress,
    LineChart,
    pieChart,
  },
  watch: {
    polygon() {
      this.init();
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      //  this.$emit("startLoading")
      this.getCardList();
      this.getGenderList();
      this.getAgeList();
      this.getEducationList();
      this.getFamilyList();
      //  this.$emit("endLoading")
    },
    // 获取客群特征
    async getCardList() {
      let params = {
        identify: "sg-data-位置-客群特征",
        filters: [
          {
            field: "geom",
            cond: 0,
            value: [this.polygon],
          },
        ],
        fields: ["总人口数", "总家庭户数"],
        pages: {
          page: 1,
          size: 10,
        },
      };
      let res = await GetCommonInfo(params);
      if (res && res.code === 200) {
        let details = res.data.items ? res.data.items[0] : [];
        this.cardList[0].value = numFormat(details[0] || 0);
        this.cardList[1].value = numFormat(details[1] || 0);
      }
    },
    // 获取性别分布
    async getGenderList() {
      let params = {
        identify: "sg-data-位置-性别分布",
        filters: [
          {
            field: "geom",
            cond: 0,
            value: [this.polygon],
          },
        ],
        fields: ["男", "女"],
        pages: {
          page: 1,
          size: 10,
        },
      };
      let res = await GetCommonInfo(params);
      // console.log("性别分布", res);
      if (res && res.code === 200) {
        let details = res.data.items ? res.data.items[0] : [];
        this.genderList[0].percent = details[0]
          ? ((details[0] / (details[0] + details[1])) * 100).toFixed(0)
          : 0;
        this.genderList[1].percent = details[1]
          ? ((details[1] / (details[0] + details[1])) * 100).toFixed(0)
          : 0;
      }
    },
    // 获取年龄分布
    async getAgeList() {
      let params = {
        identify: "sg-data-位置-年龄分布",
        filters: [
          {
            field: "geom",
            cond: 0,
            value: [this.polygon],
          },
        ],
        fields: [
          "0-10岁",
          "10-20岁",
          "20-30岁",
          "30-40岁",
          "40-50岁",
          "50-60岁",
          "60岁以上",
        ],
      };
      let res = await GetCommonInfo(params);
      // console.log("年龄分布", res);
      if (res && res.code === 200) {
        let details = res.data.items ? res.data.items[0] : [];
        this.ageList = [];
        details.forEach((item, index) => {
          this.ageList.push({
            name: res.data.fields[index],
            value: Math.floor(item * 100),
          });
        });
      }
    },
    // 获取年龄分布
    async getEducationList() {
      let params = {
        identify: "sg-data-位置-教育结构",
        filters: [
          {
            field: "geom",
            cond: 0,
            value: [this.polygon],
          },
        ],
        fields: [
          "小学教育程度",
          "初中教育程度",
          "高中教育程度",
          "大学专科教育程度",
          "大学本科教育程度",
        ],
      };
      let res = await GetCommonInfo(params);
      // console.log("教育结构", res);
      if (res && res.code === 200) {
        let details = res.data.items ? res.data.items[0] : [];
        this.educationList = [];
        let fields = ["小学", "初中", "高中", "专科", "本科及以上"];
        details.forEach((item, index) => {
          this.educationList.push({
            name: fields[index],
            value: item * 100,
          });
        });
      }
    },
    // 获取家庭户数代数分布
    async getFamilyList() {
      let params = {
        identify: "sg-data-位置-家庭户数代数分布",
        filters: [
          {
            field: "geom",
            cond: 0,
            value: [this.polygon],
          },
        ],
        fields: ["一代户", "二代户", "三代户", "四代及以上"],
      };
      let res = await GetCommonInfo(params);
      // console.log("家庭户数代数分布", res);
      if (res && res.code === 200) {
        let details = res.data.items ? res.data.items[0] : [];
        this.familyList = [];
        details.forEach((item, index) => {
          this.familyList.push({
            title: res.data.fields[index],
            percent: item * 100,
          });
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.customers-box {
}
</style>
