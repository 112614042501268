var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "progress-box",
    },
    _vm._l(_vm.list, function (item, index) {
      return _c(
        "div",
        { key: "progress_" + index, staticClass: "progress-item" },
        [
          _c(
            "div",
            { staticClass: "progress-item-title", style: { width: _vm.width } },
            [_vm._v(" " + _vm._s(item.title) + " ")]
          ),
          _c("Progress", {
            staticClass: "progress-item-content",
            class: item.class || [],
            style: { width: `calc(100% - ${_vm.width})` },
            attrs: {
              percent: _vm.getPrecent(item.percent),
              "stroke-color": item.strokeColor || "#3519FB",
            },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }