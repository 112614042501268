var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "cardBg-box",
    },
    _vm._l(_vm.list, function (item, index) {
      return _c(
        "div",
        {
          key: "cardbg_" + index,
          staticClass: "cardBg-item",
          style: {
            backgroundImage: `url(${item.image})`,
          },
        },
        [
          _c("div", { staticClass: "cardBg-item-name" }, [
            _vm._v(_vm._s(item.name)),
          ]),
          _c(
            "div",
            { staticClass: "cardBg-item-value", style: { color: item.color } },
            [_vm._v(" " + _vm._s(_vm.numFormat(item.value || 0)) + " ")]
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }