var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "format-box" },
    [
      _c("CustomData", { attrs: { dataList: _vm.commercialList } }),
      _c(
        "CustomCard",
        { attrs: { title: "业态详细分布情况", height: "300px" } },
        [
          [
            _c("CustomTabs", {
              attrs: { tabList: _vm.tabList, active: _vm.activeTab },
              on: { activeTab: _vm.tabClick },
            }),
            _c("pieChart", {
              attrs: {
                echartData: _vm.pieChartData,
                legendShow: true,
                center: _vm.center,
              },
            }),
          ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }